import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box, useDisclosure } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import FreeTrialModal from "components/FreeTrialModal";

const Layout: React.FC = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [freeTrialModalOpened, setFreeTrialModalOpened] = useState(false);

  useEffect(() => {
    // Show pop up modal only on home page and only once
    if (location.pathname === "/") {
      if (!freeTrialModalOpened) {
        setTimeout(() => {
          onOpen();
          setFreeTrialModalOpened(true);
        }, 9992000);
      }
    } else {
      onClose();
    }
  }, [location?.pathname, onOpen, onClose, freeTrialModalOpened]);

  return (
    <>
      <Header />
      <Box as="main" mt="72px">
        {props.children}
      </Box>
      <Footer />

      <Box
        as="a"
        href="https://wa.link/6ta3ui"
        target="_blank"
        rel="noreferrer noopener"
        position="fixed"
        bottom={{ base: 10, sm: 12, md: 16 }}
        right={{ base: 4, sm: 8, md: 10 }}
        shadow="md"
        rounded="full"
        overflow="hidden"
        className="animate-bounce"
      >
        <StaticImage
          src="../../images/icons/social/whatsapp.png"
          alt="WhatsApp"
          height={64}
          width={64}
        />
      </Box>

      <FreeTrialModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Layout;
